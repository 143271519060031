import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 4×6\\@75% 1RM`}</p>
    <p>{`Band Pull Aparts 4×12`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`5-Clean + Push Jerk + Split Jerk (135/95)(RX+155/105)`}</p>
    <p>{`10-Box Jump Overs`}</p>
    <p>{`15-K2E’s`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      